(
    function (global, Mustache, Si) {
        "use strict";

        Si.component('Templates', new Templates());

        function Templates() {

            var _templates = {};

            this.put = function (uri, template) {

                Mustache.parse(template);
                _templates[uri] = template;
            };

            this.get = function (uri) {

                return _templates.hasOwnProperty(uri) ? _templates[uri] : null;
            };

            this.make = function (uri, data) {

                var template = this.get(uri);
                return template ? Mustache.render(template, data) : "";
            };
        }
    }
)(window, Mustache, Si);