(
    function (global, $, Si) {

        Si.when(['Helpers']).then(ajaxComponent);

        function ajaxComponent(Helpers) {

            var _endpoint = '/ajax',
                _actions = {};

            createShortMethods('get', 'delete');
            createShortMethodsWithData('post', 'put');

            Si.component('Ajax', Ajax);

            /**
             * endpoint setter/getter
             * @param {string} [endpoint]
             * @returns {void|string}
             */
            Ajax.endpoint = function (endpoint) {
                if (!Helpers.isUndefined(endpoint)) {
                    _endpoint = Helpers.strval(endpoint);
                } else {
                    return _endpoint;
                }
            };

            /**
             * Ajax\Action getter/setter
             * @param {string} name
             * @param {Object} [config]
             * @returns {Action|null}
             */
            Ajax.action = function (name, config) {
                if (!Helpers.isUndefined(config)) {
                    _actions[name] = new Action(name, config);
                }

                return _actions.hasOwnProperty(name) ? _actions[name] : null;
            };

            /**
             * Ajax component
             */
            function Ajax() {
            }

            /**
             * Ajax\Action component
             * @param {string} name
             * @param config
             * @constructor
             */
            function Action(name, config) {
                var $name = Helpers.strval(name),
                    $config = Helpers.extend(
                        {
                            "dataType": "json"
                        }, config || {}
                    );

                /**
                 * name getter
                 * @readonly
                 * @returns {string}
                 */
                this.name = function () {

                    return $name;
                };

                /**
                 * config getter
                 * @readonly
                 * @returns {Object}
                 */
                this.config = function () {

                    return Helpers.copy($config);
                };
            }

            /**
             * Bind query methods to Action
             * @param {...string} name
             */
            function createShortMethods(name) {
                $.each(
                    arguments, function (i, name) {
                        Action.prototype[name] = function (params) {
                            return $.ajax(
                                Helpers.extend(
                                    this.config(), {
                                        "method": name,
                                        "url": Ajax.endpoint(),
                                        "data": Helpers.extend(params || {}, {'_action': this.name()})
                                    }
                                )
                            ).then(responseInterceptor, responseErrorInterceptor);
                        };
                    }
                );
            }

            /**
             * Bind data methods to Action
             * @param {...string} name
             */
            function createShortMethodsWithData(name) {
                $.each(
                    arguments, function (i, name) {
                        Action.prototype[name] = function (data) {
                            return $.ajax(
                                Helpers.extend(
                                    this.config(), {
                                        "method": name,
                                        "url": Ajax.endpoint(),
                                        "data": Helpers.extend(data || {}, {'_action': this.name()})
                                    }
                                )
                            ).then(responseInterceptor, responseErrorInterceptor);
                        };
                    }
                );
            }

            function responseInterceptor(data, textStatus, jqXHR) {
                var deferred = $.Deferred();

                deferred.resolve(data);

                return deferred.promise();
                /*$log.debug('api.resource.response', response);
                 return response.data;*/
            }

            function responseErrorInterceptor(jqXHR, textStatus, errorThrown) {
                var deferred = $.Deferred();

                deferred.reject(textStatus, errorThrown);

                return deferred.promise();
                /*$log.debug('api.resource.responseError', rejection);
                 switch (rejection.status) {
                 case 401:
                 $rootScope.$broadcast(apiEvents.unauthorized, rejection.data);
                 alertException('Unauthorized', rejection.data);
                 break;
                 case 404:
                 alertException('404', rejection.data);
                 break;
                 case 422:
                 $rootScope.$broadcast(apiEvents.failedValidation, rejection.data);
                 alertValidationException('Validation error', rejection.data);
                 break;
                 case 500:
                 /!*if ($logProvider.debugEnabled()) {
                 //@todo make service for displaying debug
                 break;
                 }*!/
                 default:
                 alertException('Bad request', ['Whoops, looks like something went wrong.']);
                 break;
                 }

                 return $q.reject(rejection);*/
            }

            /*function alertException(title, messages, template) {
             var modalInstance = $uibModal.open({
             template: (template || '<div class="modal-header">'
             + '<h3 class="modal-title">{{title}}</h3>'
             + '</div>'
             + '<div class="modal-body">'
             + '<ul class="list-group">'
             + '<li class="list-group-item list-group-item-danger" ng-repeat="message in messages">{{message}}</li>'
             + '</ul>'
             + '</div>'
             + '<div class="modal-footer">'
             + '<button class="btn btn-primary" type="button" ng-click="$close()">Ok</button>'
             + '</div>'),
             size: 'sm',
             controller: [
             '$scope',
             function ($scope) {
             $scope.title = title;
             $scope.messages = messages;
             }
             ]
             });
             }

             function alertValidationException(title, messages) {
             var allMessages = [];
             ng.forEach(messages, function (aMessages, attribute, messages) {
             allMessages = allMessages.concat(aMessages);
             });
             alertException(title, allMessages);
             }*/
        }
    }
)(window, jQuery, Si);