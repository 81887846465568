(
    function (global, $) {
        "use strict";

        var _components = {},
            deferreds = {};

        global['Si'] = {
            component: component,
            when: when
        };

        function component(name, obj) {
            _components[name] = obj;
            var deferred = _assertComponentDeferred(name);
            deferred.resolve(obj);
        }

        function when(components) {
            var promises = [];

            components = $.isArray(components) ? components : Array.prototype.slice.call(arguments, 0);
            $.each(
                components, function (i, name) {
                    var deferred = _assertComponentDeferred(name);
                    promises.push(deferred.promise());
                }
            );

            return $.when.apply($, promises);
        }

        function _assertComponentDeferred(name) {
            if (!deferreds.hasOwnProperty(name)) {
                deferreds[name] = $.Deferred();
            }

            return deferreds[name];
        }
    }
)(window, jQuery);