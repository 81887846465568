(
    function (global, Si) {
        "use strict";

        Si.when(['Ajax']).then(timezoneAction);

        function timezoneAction(Ajax) {
            var timezoneAction = Ajax.action('timezone', {});

            Si.component('Ajax/Timezone', timezoneAction);
        }
    }
)(window, Si);