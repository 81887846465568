(
    function (global, $, Si) {
        "use strict";

        /**
         * Helpers collection
         * @type {Object}
         */
        var Helpers = {
            isUndefined: isUndefined,
            isString: isString,
            strval: strval,
            extend: extend,
            merge: merge,
            copy: copy,
            formatServerDateTime: formatServerDateTime,
            serverDateTimeToMoment: serverDateTimeToMoment,
            latestMoment: latestMoment
        };

        Si.component('Helpers', Helpers);

        formatServerDateTime.SERVER_DATE_FORMAT = "YYYY-MM-DD";
        formatServerDateTime.SERVER_TIME_FORMAT = "HH:mm:ss";
        formatServerDateTime.SERVER_DATETIME_FORMAT = formatServerDateTime.SERVER_DATE_FORMAT + " "
                                                      + formatServerDateTime.SERVER_TIME_FORMAT;
        formatServerDateTime.DISPLAY_DATE_FORMAT = "D MMM YYYY";
        formatServerDateTime.DISPLAY_TIME_FORMAT = "HH:mm";
        formatServerDateTime.DISPLAY_DATETIME_FORMAT = formatServerDateTime.DISPLAY_DATE_FORMAT + " "
                                                       + formatServerDateTime.DISPLAY_TIME_FORMAT;


        function formatServerDateTime(value, localize, fromFormat, toFormat) {

            toFormat = toFormat || formatServerDateTime.DISPLAY_DATETIME_FORMAT;

            value = serverDateTimeToMoment(value, localize, fromFormat);//global.moment.utc(value, fromFormat);

            /*if (localize) {

                value.local();
            }*/

            return value.format(toFormat);
        }

        function serverDateTimeToMoment(value, localize, fromFormat) {

            localize = !!localize;
            fromFormat = fromFormat || formatServerDateTime.SERVER_DATETIME_FORMAT;

            value = global.moment.utc(value, fromFormat);
            if (localize) {

                value.local();
            }

            return value;
        }

        /**
         * @param {...Moment|null} moments
         * @return {Moment|null}
         */
        function latestMoment(moments) {

            moments = Array.prototype.slice.call(arguments);

            if (!moments.length) {

                return null;
            }

            var latest = moments[0] || null;

            for (var i = 1; i < moments.length; i++) {

                if (!latest) {

                    latest = moments[i] || null;
                } else if(moments[i] && moments[i].isAfter(latest)) {

                    latest = moments[i];
                }
            }

            return latest;
        }

        /**
         * Make an object copy
         * @param {Object} obj
         * @returns {Object}
         */
        function copy(obj) {
            return merge({}, obj);
        }

        /**
         * Deeply extend object
         * @param {Object} dst
         * @param {...Object} src
         * @returns {Object}
         */
        function merge(dst) {
            var args = [true, dst || {}].concat(arguments.length > 1 ? Array.prototype.slice.call(arguments, 1) : [{}]);
            return $.extend.apply(null, args);
        }

        /**
         * Extend object
         * @param {Object} dst
         * @param {...Object} src
         * @returns {Object}
         */
        function extend(dst) {
            var args = [false, dst || {}].concat(
                arguments.length > 1 ? Array.prototype.slice.call(arguments, 1) : [{}]
            );
            return $.extend.apply(null, args);
        }

        /**
         * Make value string
         * @param {*} value
         * @returns {string}
         */
        function strval(value) {
            return "" + value;
        }

        /**
         * Determines if a reference is string
         * @param {*} value
         * @returns {boolean}
         */
        function isString(value) {
            return typeof value === 'string';
        }

        /**
         * Determines if a reference is undefined
         * @param {*} value
         * @returns {boolean}
         */
        function isUndefined(value) {
            return typeof value === 'undefined';
        }
    }
)(window, jQuery, Si);