(
    function (global, Si) {
        "use strict";

        Si.when(['Ajax']).then(subscribeAction);

        function subscribeAction(Ajax) {
            var subscribeAction = Ajax.action('subscribe', {});

            Si.component('Ajax/Subscribe', subscribeAction);
        }
    }
)(window, Si);